import React, { useState } from 'react';
import "../assets/css/create-post.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const CreatePostPage = () => {
  const [seekerVideoMessage, setSeekerVideoMessage] = useState('');
  const [seekerVideoInProcess, setSeekerVideoInProcess] = useState(false);
  const [placeVideoInProcess, setPlaceVideoInProcess] = useState(false);
  const [docInProcess, setDocInProcess] = useState(false);
  const [placeVideoMessage, setPlaceVideoMessage] = useState('');
  const [docUploadMessage, setDocUploadMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    post_type: '',
    need: '',
    seeker: '',
    description: '',
    address: '',
    phone_number: '',
    needed_money: 0,
    verified: 0,

    seeker_video: '',
    place_video: '',
    electric_bill: '',

    bank_name: '',
    account_number: '',
    account_holder: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleSeekerFileChange = async (e) => {
    const file = e.target.files[0]; 
    const formData = new FormData();
    formData.append('file', file);
    setSeekerVideoInProcess(true)
    setSeekerVideoMessage('AI is verifying your video...');
    
    try {
      const response = await axios.post( process.env.REACT_APP_BACKEND_DOMAIN + '/post/upload-seeker-videos/', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setFormData(prevFormData => ({
          ...prevFormData,
          seeker_video: response.data.url,
          verified: response.data.verified,
        }));
        setSeekerVideoInProcess(false)
        setSeekerVideoMessage('Seeker video uploaded successfully');
      } else {
        setSeekerVideoInProcess(false)
        setSeekerVideoMessage('Faild to upload seeker video, try again');
      }
    } catch (error) {
      setSeekerVideoInProcess(false)
      setSeekerVideoMessage('Error uploading seeker video');
    }
  };

  const handlePlaceFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    setPlaceVideoInProcess(true)
    setPlaceVideoMessage('uploading file...');

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN + '/post/upload-place-videos/', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setFormData(prevFormData => ({
          ...prevFormData,
          place_video: response.data.url,
        }));
        setPlaceVideoInProcess(false)
        setPlaceVideoMessage('Place video uploaded successfully');

      } else {
        setPlaceVideoInProcess(false)
        setPlaceVideoMessage('Failed to upload Place video');
      }
    } catch (error) {
      setPlaceVideoInProcess(false)
      setPlaceVideoMessage('Error uploading Place video');
    }
  };

  const handleDocFileChange = async (e) => {
    const file = e.target.files[0]; // it
    const formData = new FormData();
    formData.append('file', file);
    setDocInProcess(true)
    setDocUploadMessage('uploading document...');

    try {
      const response = await axios.post( process.env.REACT_APP_BACKEND_DOMAIN + '/post/upload-post-docs/', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setFormData(prevFormData => ({
          ...prevFormData,
          electric_bill: response.data.url,
        }));
        setDocInProcess(false)
        setDocUploadMessage('electric bill uploaded successfully');
      } else {
        setDocInProcess(false)
        setDocUploadMessage('Failed to upload seeker electric bill');
      }
    } catch (error) {
      setDocInProcess(false)
      setDocUploadMessage('error uploading electric bill');
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const jsonData = JSON.stringify(formData);
  
    // Create post
    const config = {
      headers: {
        'Content-Type': 'application/json', // Use JSON content type
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      }
    };

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN +'/post/create-post/', jsonData, config);
      setIsLoading(false)
      if (response.status === 201) {
        navigate('/detail/' + response.data.slug)
      }
    } catch (error) {
      setIsLoading(false);

      if (error.response && error.response.data) {
        setErrorMessage('Error occurred while creating post');
      }else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      };
    };
  };


  return (
    <div className="page bg-white  dark:bg-gray-700" >
      <form onSubmit={handleSubmit} className='create-form-card' encType="multipart/form-data">

        <h1 className="text-3xl font-bold mb-8">Create Post</h1>

        <div className='grid grid-cols-2 gap-4'>
        <div>
        {/* Seeker */}
        <div className="mb-4 field-width">
          <label htmlFor="seeker" className="block font-semibold mb-2">Name</label>
          <input
            type="text"
            id="seeker"
            name="seeker"
            placeholder='Name of Seeker: Bilal Ahmed'
            value={formData.seeker}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        {/* Address */}
        <div className="mb-4 field-width">
          <label htmlFor="address" className="block font-semibold mb-2">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            placeholder='Address: 123 Main Street, Anytown USA'
            value={formData.address}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        {/* Phone Number */}
        <div className="mb-4 field-width">
          <label htmlFor="phone_number" className="block font-semibold mb-2">Phone Number</label>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            placeholder='03xx-xxxxxx21'
            value={formData.phone_number}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>
        </div>
        
        <div>
        {/* Seeker Video */}
        <div className="mb-4 field-width">
          <label htmlFor="seeker_video" className="block font-semibold mb-2">Seeker Video</label>
          <input
            type="file"
            id="seeker_video"
            name="seeker_video"
            required
            disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
            onChange={handleSeekerFileChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
          />
          {seekerVideoMessage && <div>{seekerVideoMessage}</div>}
        </div>
        
        {/* Place Video */}
        <div className="mb-4 field-width">
          <label htmlFor="place_video" className="block font-semibold mb-2">Place Video</label>
          <input
            type="file"
            id="place_video"
            required
            disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
            name="place_video"
            onChange={handlePlaceFileChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
          />
          {placeVideoMessage && <div>{placeVideoMessage}</div>}
        </div>

        <div className="mb-4 field-width">
          <label htmlFor="electric_bill" className="block font-semibold mb-2">Electric Bill</label>
          <input
            type="file"
            id="electric_bill"
            required
            name="electric_bill"
            disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
            onChange={handleDocFileChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
          />
          {docUploadMessage && <div>{docUploadMessage}</div>}
        </div>
        

        </div>

        </div>

        <div className='grid grid-cols-2 gap-4'>
        
        <div className='mt-10'>
        {/* Post Type */}
        <div className="mb-4 field-width">
          <label htmlFor="post_type" className="block font-semibold mb-2">Asking for What?</label>
          <select
            id="post_type"
            name="post_type"
            value={formData.post_type}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          >
            <option value="">Select Purpose</option>
            <option value="family">Asking for Family</option>
            <option value="masjid">Asking for Masjid</option>
            <option value="madrasa">Asking for Madrasa</option>
            <option value="ngo">Asking for NGO</option>
            <option value="person">Asking for Myself</option>
          </select>
        </div>

        {/* need */}
        <div className="mb-4 field-width">
          <label htmlFor="need" className="block font-semibold mb-2">What you need?</label>
          <select
            id="need"
            name="need"
            value={formData.need}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          >
            <option value="">Select Need</option>
            <option value="zakat">Zakat</option>
            <option value="donation">Donation</option>
            <option value="help">Help</option>
          </select>
        </div>

        {/* Needed Money */}
        <div className="mb-4 field-width">
        <label htmlFor="needed_money" className="block font-semibold mb-2">How much money do you need?</label>
          <input
            type="number"
            id="needed_money"
            name="needed_money"
            value={formData.needed_money}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>



        </div>
        

        
      <div className='mt-10'>

        {/* Bank Name */}
        <div className="mb-4 field-width">
          <label htmlFor="bank_name" className="block font-semibold mb-2">Bank Name</label>
          <input
            type="text"
            id="bank_name"
            name="bank_name"
            value={formData.bank_name}
            required
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
          />
        </div>

        {/* Account Holder */}
        <div className="mb-4 field-width">
          <label htmlFor="account_holder" className="block font-semibold mb-2">Account Holder Name</label>
          <input
            type="text"
            id="account_holder"
            required
            name="account_holder"
            value={formData.account_holder}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
          />
        </div>

        {/* Account Number */}
        <div className="mb-4 field-width">
          <label htmlFor="account_number" className="block font-semibold mb-2">Account Number</label>
          <input
            type="text"
            id="account_number"
            name="account_number"
            required
            value={formData.account_number}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
          />
        </div>



        </div>

        </div>

        {/* Description */}
        <div className="mb-4 post-description">
          <label htmlFor="description" className="block font-semibold mb-2">Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        {/* Submit Button */}
        <div className="text-center">
          {isLoading ? (
            <div className="inline-block loading-icon">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
            </div>
          ) : (
            <button
              type="submit"
              className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
            >
              Create Post
            </button>
          )}

          {errorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</div>}

          {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
            <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
              Please wait until the file is uploaded
            </div>
          )}
        </div>

        
      </form>
    </div>
  );
};

export default CreatePostPage;
